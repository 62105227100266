
.product-container {
    height: 350px; /* Adjust the height as needed */
    overflow: hidden;
    background-color: yellow;
}
.product-image {
    width: 100%; /* Make sure the image takes up the full width of the container */
    height: 100%; /* Make sure the image takes up the full height of the container */
    object-fit: cover; /* Resize the image to cover the container while maintaining aspect ratio */
}

.sidebar-divider-padded-top{
    border-top: 1px solid #333333;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 8px;
}
.sidebar-divider-padded-bottom{
    border-top: 1px solid #333333;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 8px;
}
/********************************************************** MEDIA QUERRIES ******************************************************************/

/* Phone (landscape) */
@media (min-width: 576px){



}

/* Tablet (portrait) */
@media(min-width:768px){



}

/* Tablet (landscape) */
@media(min-width:992px){



}

/* Desktop */
@media(min-width:1200px){



}

@media(min-width:1400px){



}

@media(min-width:1600px){

}

@media(min-width:1800px){

}


/*************** Typography Styles ***************/


.flex-1{
    flex: 1;
}
.text-block.xs .block{
    max-width: 400px;
}
.text-block.sm .block{
    max-width: 600px;
}
.text-block.lg .block{
    max-width: 800px;
}
.text-block.xl .block{
    max-width: 1000px;
}
.lead{
    font-size: 16px !important;
}
.lead-set,
.lead.lg{
    font-size: 20px !important;
}
.lead.lg-set,
.lead.xl{
    font-size: 24px !important;
}
.lead.xl-set{
    font-size: 30px !important;
}
.text-xs{
    font-size: 10px !important;
}
.text-xs-set,
.text-sm,
.text-grow{
    font-size: 12px !important;
}
.text-sm-set{
    font-size: 14px !important;
}
.ls0{
    letter-spacing: 0 !important;
}
.ls1{
    letter-spacing: 1px !important;
}
.ls2{
    letter-spacing: 2px !important;
}
.ls3{
    letter-spacing: 3px !important;
}
.ls4{
    letter-spacing: 4px !important;
}
.ls5{
    letter-spacing: 5px !important;
}
.ls6{
    letter-spacing: 6px !important;
}
.ls7{
    letter-spacing: 7px !important;
}
.ls8{
    letter-spacing: 8px !important;
}
.ls9{
    letter-spacing: 9px !important;
}
.ls10{
    letter-spacing: 10px !important;
}
h1.main,
h1 .main,
.h1.main,
.h1 .main,
h2.main,
h2 .main,
.h2.main,
.h2 .main{
    font-size: 30px;
}
h1.main-set,
h1 .main-set,
.h1.main-set,
.h1 .main-set{
    font-size: 50px;
}
h1.main.xlish-set,
h1 .main.xlish-set,
.h1.main.xlish-set,
.h1 .main.xlish-set{
    font-size: 60px;
}
h1.main.xl-set,
h1 .main.xl-set,
.h1.main.xl-set,
.h1 .main.xl-set{
    font-size: 80px;
}
h1.main.xlish,
h1 .main.xlish,
.h1.main.xlish,
.h1 .main.xlish,
h2.main.xlish,
h2 .main.xlish,
.h2.main.xlish,
.h2 .main.xlish{
    font-size: 36px;
}
h1.main.xl,
h1 .main.xl,
.h1.main.xl,
.h1 .main.xl,
h2.main.xl,
h2 .main.xl,
.h2.main.xl,
.h2 .main.xl,
h3.main-set,
h3 .main-set,
.h3.main-set,
.h3 .main-set{
    font-size: 40px;
}
h1.main.xler,
h1 .main.xler,
.h1.main.xler,
.h1 .main.xler{
    font-size: 60px;
}
h1.main.xlest,
h1 .main.xlest,
.h1.main.xlest,
.h1 .main.xlest{
    font-size: 80px;
}
h3.main,
h3 .main,
.h3.main,
.h3 .main{
    font-size: 22px;
}
.bord-all{
    border: 1px solid !important;
}
.bord-top{
    border-top: 1px solid !important;
}
.bord-right{
    border-right: 1px solid !important;
}
.bord-bottom{
    border-bottom: 1px solid !important;
}
.bord-left{
    border-left: 1px solid !important;
}

/* Phone (landscape) */
@media (min-width: 576px){
    .flex-sm-1{
        flex: 1;
    }
    .ls-sm-0{
        letter-spacing: 0 !important;
    }
    .ls-sm-1{
        letter-spacing: 1px !important;
    }
    .ls-sm-2{
        letter-spacing: 2px !important;
    }
    .ls-sm-3{
        letter-spacing: 3px !important;
    }
    .ls-sm-4{
        letter-spacing: 4px !important;
    }
    .ls-sm-5{
        letter-spacing: 5px !important;
    }
    .ls-sm-6{
        letter-spacing: 6px !important;
    }
    .ls-sm-7{
        letter-spacing: 7px !important;
    }
    .ls-sm-8{
        letter-spacing: 8px !important;
    }
    .ls-sm-9{
        letter-spacing: 9px !important;
    }
    .ls-sm-10{
        letter-spacing: 10px !important;
    }
    h1.main,
    h1 .main,
    .h1.main,
    .h1 .main,
    h2.main,
    h2 .main,
    .h2.main,
    .h2 .main{
        font-size: 34px;
    }
    h1.main.xlish,
    h1 .main.xlish,
    .h1.main.xlish,
    .h1 .main.xlish,
    h2.main.xlish,
    h2 .main.xlish,
    .h2.main.xlish,
    .h2 .main.xlish{
        font-size: 40px;
    }
    h1.main.xl,
    h1 .main.xl,
    .h1.main.xl,
    .h1 .main.xl,
    h2.main.xl,
    h2 .main.xl,
    .h2.main.xl,
    .h2 .main.xl{
        font-size: 54px;
    }
    h1.main.xler,
    h1 .main.xler,
    .h1.main.xler,
    .h1 .main.xler{
        font-size: 70px;
    }
    h1.main.xlest,
    h1 .main.xlest,
    .h1.main.xlest,
    .h1 .main.xlest{
        font-size: 100px;
    }
    h3.main,
    h3 .main,
    .h3.main,
    .h3 .main{
        font-size: 26px;
    }
    .bord-sm-all{
        border: 1px solid !important;
    }
    .bord-sm-top{
        border-top: 1px solid !important;
    }
    .bord-sm-right{
        border-right: 1px solid !important;
    }
    .bord-sm-bottom{
        border-bottom: 1px solid !important;
    }
    .bord-sm-left{
        border-left: 1px solid !important;
    }

}

/* Tablet (portrait) */
@media(min-width:768px){
    .flex-md-1{
        flex: 1;
    }
    .remodal h2,
    .remodal h3{
        font-size: 26px;
    }
    .ls-md-0{
        letter-spacing: 0 !important;
    }
    .ls-md-1{
        letter-spacing: 1px !important;
    }
    .ls-md-2{
        letter-spacing: 2px !important;
    }
    .ls-md-3{
        letter-spacing: 3px !important;
    }
    .ls-md-4{
        letter-spacing: 4px !important;
    }
    .ls-md-5{
        letter-spacing: 5px !important;
    }
    .ls-md-6{
        letter-spacing: 6px !important;
    }
    .ls-md-7{
        letter-spacing: 7px !important;
    }
    .ls-md-8{
        letter-spacing: 8px !important;
    }
    .ls-md-9{
        letter-spacing: 9px !important;
    }
    .ls-md-10{
        letter-spacing: 10px !important;
    }
    h1.main,
    h1 .main,
    .h1.main,
    .h1 .main,
    h2.main,
    h2 .main,
    .h2.main,
    .h2 .main{
        font-size: 38px;
    }
    h1.main.xlish,
    h1 .main.xlish,
    .h1.main.xlish,
    .h1 .main.xlish,
    h2.main.xlish,
    h2 .main.xlish,
    .h2.main.xlish,
    .h2 .main.xlish{
        font-size: 46px;
    }
    h1.main.xl,
    h1 .main.xl,
    .h1.main.xl,
    .h1 .main.xl,
    h2.main.xl,
    h2 .main.xl,
    .h2.main.xl,
    .h2 .main.xl{
        font-size: 64px;
    }
    h1.main.xler,
    h1 .main.xler,
    .h1.main.xler,
    .h1 .main.xler{
        font-size: 80px;
    }
    h1.main.xlest,
    h1 .main.xlest,
    .h1.main.xlest,
    .h1 .main.xlest{
        font-size: 110px;
    }
    h3.main,
    h3 .main,
    .h3.main,
    .h3 .main{
        font-size: 30px;
    }
    .bord-md-all{
        border: 1px solid !important;
    }
    .bord-md-top{
        border-top: 1px solid !important;
    }
    .bord-md-right{
        border-right: 1px solid !important;
    }
    .bord-md-bottom{
        border-bottom: 1px solid !important;
    }
    .bord-md-left{
        border-left: 1px solid !important;
    }

}

/* Tablet (landscape) */
@media(min-width:992px){
    .flex-lg-1{
        flex: 1;
    }
    .lead{
        font-size: 18px !important;
    }
    .lead.lg{
        font-size: 22px !important;
    }
    .lead.xl{
        font-size: 26px !important;
    }
    .text-grow{
        font-size: 14px !important;
    }
    .ls-lg-0{
        letter-spacing: 0 !important;
    }
    .ls-lg-1{
        letter-spacing: 1px !important;
    }
    .ls-lg-2{
        letter-spacing: 2px !important;
    }
    .ls-lg-3{
        letter-spacing: 3px !important;
    }
    .ls-lg-4{
        letter-spacing: 4px !important;
    }
    .ls-lg-5{
        letter-spacing: 5px !important;
    }
    .ls-lg-6{
        letter-spacing: 6px !important;
    }
    .ls-lg-7{
        letter-spacing: 7px !important;
    }
    .ls-lg-8{
        letter-spacing: 8px !important;
    }
    .ls-lg-9{
        letter-spacing: 9px !important;
    }
    .ls-lg-10{
        letter-spacing: 10px !important;
    }
    h1.main,
    h1 .main,
    .h1.main,
    .h1 .main,
    h2.main,
    h2 .main,
    .h2.main,
    .h2 .main{
        font-size: 44px;
    }
    h1.main.xlish,
    h1 .main.xlish,
    .h1.main.xlish,
    .h1 .main.xlish,
    h2.main.xlish,
    h2 .main.xlish,
    .h2.main.xlish,
    .h2 .main.xlish{
        font-size: 54px;
    }
    h1.main.xl,
    h1 .main.xl,
    .h1.main.xl,
    .h1 .main.xl,
    h2.main.xl,
    h2 .main.xl,
    .h2.main.xl,
    .h2 .main.xl{
        font-size: 72px;
    }
    h1.main.xler,
    h1 .main.xler,
    .h1.main.xler,
    .h1 .main.xler{
        font-size: 90px;
    }
    h1.main.xlest,
    h1 .main.xlest,
    .h1.main.xlest,
    .h1 .main.xlest{
        font-size: 130px;
    }
    h3.main,
    h3 .main,
    .h3.main,
    .h3 .main{
        font-size: 36px;
    }
    .bord-lg-all{
        border: 1px solid !important;
    }
    .bord-lg-top{
        border-top: 1px solid !important;
    }
    .bord-lg-right{
        border-right: 1px solid !important;
    }
    .bord-lg-bottom{
        border-bottom: 1px solid !important;
    }
    .bord-lg-left{
        border-left: 1px solid !important;
    }

}

/* Desktop */
@media(min-width:1200px){
    .flex-xl-1{
        flex: 1;
    }
    .lead{
        font-size: 20px !important;
    }
    .lead.lg{
        font-size: 24px !important;
    }
    .lead.xl{
        font-size: 30px !important;
    }
    .text-xs{
        font-size: 12px !important;
    }
    .text-sm{
        font-size: 14px !important;
    }
    .text-grow{
        font-size: 14px !important;
    }
    .card-columns{
        -webkit-column-gap: 1rem !important;
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important;
    }
    .card-columns.four{
        -webkit-column-count: 4 !important;
        -moz-column-count: 4 !important;
        column-count: 4 !important;
    }
    .ls-xl-0{
        letter-spacing: 0 !important;
    }
    .ls-xl-1{
        letter-spacing: 1px !important;
    }
    .ls-xl-2{
        letter-spacing: 2px !important;
    }
    .ls-xl-3{
        letter-spacing: 3px !important;
    }
    .ls-xl-4{
        letter-spacing: 4px !important;
    }
    .ls-xl-5{
        letter-spacing: 5px !important;
    }
    .ls-xl-6{
        letter-spacing: 6px !important;
    }
    .ls-xl-7{
        letter-spacing: 7px !important;
    }
    .ls-xl-8{
        letter-spacing: 8px !important;
    }
    .ls-xl-9{
        letter-spacing: 9px !important;
    }
    .ls-xl-10{
        letter-spacing: 10px !important;
    }
    h1.main,
    h1 .main,
    .h1.main,
    .h1 .main,
    h2.main,
    h2 .main,
    .h2.main,
    .h2 .main{
        font-size: 50px;
    }
    h1.main.xlish,
    h1 .main.xlish,
    .h1.main.xlish,
    .h1 .main.xlish,
    h2.main.xlish,
    h2 .main.xlish,
    .h2.main.xlish,
    .h2 .main.xlish{
        font-size: 60px;
    }
    h1.main.xl,
    h1 .main.xl,
    .h1.main.xl,
    .h1 .main.xl,
    h2.main.xl,
    h2 .main.xl,
    .h2.main.xl,
    .h2 .main.xl{
        font-size: 80px;
    }
    h1.main.xler,
    h1 .main.xler,
    .h1.main.xler,
    .h1 .main.xler{
        font-size: 100px;
    }
    h1.main.xlest,
    h1 .main.xlest,
    .h1.main.xlest,
    .h1 .main.xlest{
        font-size: 150px;
    }
    h3.main,
    h3 .main,
    .h3.main,
    .h3 .main{
        font-size: 40px;
    }
    .bord-xl-all{
        border: 1px solid !important;
    }
    .bord-xl-top{
        border-top: 1px solid !important;
    }
    .bord-xl-right{
        border-right: 1px solid !important;
    }
    .bord-xl-bottom{
        border-bottom: 1px solid !important;
    }
    .bord-xl-left{
        border-left: 1px solid !important;
    }

}

@media(min-width:1400px){


}

@media(min-width:1600px){


}

@media(min-width:1800px){


}

.font-primary{
    font-family: var(--primary-font) !important;
}
.font-secondary{
    font-family: var(--secondary-font) !important;
}
.f200{
    font-weight: 200 !important;
}
.f300{
    font-weight: 300 !important;
}
.f400{
    font-weight: 400 !important;
}
.f500{
    font-weight: 500 !important;
}
.f600{
    font-weight: 600 !important;
}
.f700{
    font-weight: 700 !important;
}
.f800{
    font-weight: 800 !important;
}
.f900{
    font-weight: 900 !important;
}
.smlh{
    line-height: .8 !important;
}
.plh{
    line-height: 1 !important;
}
.mlh{
    line-height: 1.2 !important;
}
.slh{
    line-height: 1.4 !important;
}
.tlh{
    line-height: 1.6 !important;
}
.stlh{
    line-height: 2 !important;
}
.text-primary-color,
.text-primary-color.link a,
a.text-primary-color.link,
a .text-primary-color.link,
.text-secondary-color.link.primary a:hover,
.text-secondary-color.link.primary a:focus,
a.text-secondary-color.link.primary:hover,
a.text-secondary-color.link.primary:focus,
a .text-secondary-color.link.primary:hover,
a .text-secondary-color.link.primary a:focus,
.text-secondary-color-hover.link.primary a:hover,
.text-secondary-color-hover.link.primary a:focus,
a.text-secondary-color-hover.link.primary:hover,
a.text-secondary-color-hover.link.primary:focus,
a .text-secondary-color-hover.link.primary:hover,
a .text-secondary-color-hover.link.primary a:focus,
.text-extra-light-gray-color.link.primary a:hover,
.text-extra-light-gray-color.link.primary a:focus,
a.text-extra-light-gray-color.link.primary:hover,
a.text-extra-light-gray-color.link.primary:focus,
a .text-extra-light-gray-color.link.primary:hover,
a .text-extra-light-gray-color.link.primary a:focus,
.text-white.link.primary a:hover,
.text-white.link.primary a:focus,
a.text-white.link.primary:hover,
a.text-white.link.primary:focus,
a .text-white.link.primary:hover,
a .text-white.link.primary:focus,
.text-black-color.link.primary a:hover,
.text-black-color.link.primary a:focus,
a.text-black-color.link.primary:hover,
a.text-black-color.link.primary:focus,
a .text-black-color.link.primary:hover,
a .text-black-color.link.primary:focus,
.text-light-gray-color.link.primary a:hover,
.text-light-gray-color.link.primary a:focus,
a.text-light-gray-color.link.primary:hover,
a.text-light-gray-color.link.primary:focus,
a .text-light-gray-color.link.primary:hover,
a .text-light-gray-color.link.primary:focus,
.text-gray-color.link.primary a:hover,
.text-gray-color.link.primary a:focus,
a.text-gray-color.link.primary:hover,
a.text-gray-color.link.primary:focus,
a .text-gray-color.link.primary:hover,
a .text-gray-color.link.primary:focus,
.text-dark-gray-color.link.primary a:hover,
.text-dark-gray-color.link.primary a:focus,
a.text-dark-gray-color.link.primary:hover,
a.text-dark-gray-color.link.primary:focus,
a .text-dark-gray-color.link.primary:hover,
a .text-dark-gray-color.link.primary:focus,
.text-alternate-color.link.primary a:hover,
.text-alternate-color.link.primary a:focus,
a.text-alternate-color.link.primary:hover,
a.text-alternate-color.link.primary:focus,
a .text-alternate-color.link.primary:hover,
a .text-alternate-color.link.primary:focus{
    color: var(--primary-color) !important;
}
.text-primary-color-hover,
.text-primary-color-hover.link a,
a.text-primary-color-hover.link,
a .text-primary-color-hover.link,
.text-primary-color.link.hover a:hover,
.text-primary-color.link.hover a:focus,
a.text-primary-color.link.hover:hover,
a.text-primary-color.link.hover:focus,
a .text-primary-color.link.hover:hover,
a .text-primary-color.link.hover a:focus,
.text-gray-color.link.primary-hover a:hover,
.text-gray-color.link.primary-hover a:focus,
a.text-gray-color.link.primary-hover:hover,
a.text-gray-color.link.primary-hover:focus,
a .text-gray-color.link.primary-hover:hover,
a .text-gray-color.link.primary-hover:focus{
    color: var(--primary-color-hover) !important;
}
.text-secondary-color,
.text-secondary-color.link a,
a.text-secondary-color.link,
a .text-secondary-color.link,
.text-primary-color.link.secondary a:hover,
.text-primary-color.link.secondary a:focus,
a.text-primary-color.link.secondary:hover,
a.text-primary-color.link.secondary:focus,
a .text-primary-color.link.secondary:hover,
a .text-primary-color.link.secondary a:focus,
.text-white.link.secondary a:hover,
.text-white.link.secondary a:focus,
a.text-white.link.secondary:hover,
a.text-white.link.secondary:focus,
a .text-white.link.secondary:hover,
a .text-white.link.secondary:focus,
.text-dark-gray-color.link.secondary a:hover,
.text-dark-gray-color.link.secondary a:focus,
a.text-dark-gray-color.link.secondary:hover,
a.text-dark-gray-color.link.secondary:focus,
a .text-dark-gray-color.link.secondary:hover,
a .text-dark-gray-color.link.secondary:focus,
.text-gray-color.link.secondary a:hover,
.text-gray-color.link.secondary a:focus,
a.text-gray-color.link.secondary:hover,
a.text-gray-color.link.secondary:focus,
a .text-gray-color.link.secondary:hover,
a .text-gray-color.link.secondary:focus,
.text-light-gray-color.link.secondary a:hover,
.text-light-gray-color.link.secondary a:focus,
a.text-light-gray-color.link.secondary:hover,
a.text-light-gray-color.link.secondary:focus,
a .text-light-gray-color.link.secondary:hover,
a .text-light-gray-color.link.secondary:focus,
.text-black-color.link.secondary a:hover,
.text-black-color.link.secondary a:focus,
a.text-black-color.link.secondary:hover,
a.text-black-color.link.secondary:focus,
a .text-black-color.link.secondary:hover,
a .text-black-color.link.secondary:focus{
    color: var(--secondary-color) !important;
}
.text-secondary-color-hover,
.text-secondary-color-hover.link a,
a.text-secondary-color-hover.link,
a .text-secondary-color-hover.link,
.text-secondary-color.link.hover a:hover,
.text-secondary-color.link.hover a:focus,
a.text-secondary-color.link.hover:hover,
a.text-secondary-color.link.hover:focus,
a .text-secondary-color.link.hover:hover,
a .text-secondary-color.link.hover a:focus,
.text-white.link.secondary-hover a:hover,
.text-white.link.secondary-hover a:focus,
a.text-white.link.secondary-hover:hover,
a.text-white.link.secondary-hover:focus,
a .text-white.link.secondary-hover:hover,
a .text-white.link.secondary-hover:focus,
.text-gray-color.link.secondary-hover a:hover,
.text-gray-color.link.secondary-hover a:focus,
a.text-gray-color.link.secondary-hover:hover,
a.text-gray-color.link.secondary-hover:focus,
a .text-gray-color.link.secondary-hover:hover,
a .text-gray-color.link.secondary-hover:focus{
    color: var(--secondary-color-hover) !important;
}
.text-alternate-color,
.text-alternate-color.link a,
a.text-alternate-color.link,
a .text-alternate-color.link{
    color: var(--alternate-color) !important;
}
.text-lightest-gray-color{
    color: var(--lightest-gray-color) !important;
}
.text-extra-light-gray-color,
a.text-extra-light-gray-color.link,
a .text-extra-light-gray-color.link,
.text-extra-light-gray-color.link a,
.text-white.link.extra-light-gray a:hover,
.text-white.link.extra-light-gray a:focus,
a.text-white.link.extra-light-gray:hover,
a.text-white.link.extra-light-gray:focus,
a .text-white.link.extra-light-gray:hover,
a .text-white.link.extra-light-gray:focus{
    color: var(--extra-light-gray-color) !important;
}
.text-light-gray-color,
a.text-light-gray-color.link,
a .text-light-gray-color.link,
.text-light-gray-color.link a,
.text-black-color.link.light-gray a:hover,
.text-black-color.link.light-gray a:focus,
a.text-black-color.link.light-gray:hover,
a.text-black-color.link.light-gray:focus,
a .text-black-color.link.light-gray:hover,
a .text-black-color.link.light-gray:focus{
    color: var(--light-gray-color) !important;
}
.text-gray-color,
a.text-gray-color.link,
a .text-gray-color.link,
.text-gray-color.link a{
    color: var(--gray-color) !important;
}
.text-dark-gray-color,
a.text-dark-gray-color.link,
a .text-dark-gray-color.link,
.text-dark-gray-color.link a{
    color: var(--dark-gray-color) !important;
}
.text-black-color,
a.text-black-color.link,
a .text-black-color.link,
.text-black-color.link a{
    color: var(--black-color) !important;
}
.text-white.link a,
a.text-white.link,
a .text-white.link,
.text-white.link a,
.text-primary-color.link.white a:hover,
.text-primary-color.link.white a:focus,
a.text-primary-color.link.white:hover,
a.text-primary-color.link.white:focus,
a .text-primary-color.link.white:hover,
a .text-primary-color.link.white:focus,
a:hover .text-primary-color.link.white,
a:focus .text-primary-color.link.white,
.text-secondary-color.link.white a:hover,
.text-secondary-color.link.white a:focus,
a.text-secondary-color.link.white:hover,
a.text-secondary-color.link.white:focus,
a .text-secondary-color.link.white:hover,
a .text-secondary-color.link.white a:focus,
.text-secondary-color-hover.link.white a:hover,
.text-secondary-color-hover.link.white a:focus,
a.text-secondary-color-hover.link.white:hover,
a.text-secondary-color-hover.link.white:focus,
a .text-secondary-color-hover.link.white:hover,
a .text-secondary-color-hover.link.white a:focus,
.text-extra-light-gray-color.link.white a:hover,
.text-extra-light-gray-color.link.white a:focus,
a.text-extra-light-gray-color.link.white:hover,
a.text-extra-light-gray-color.link.white:focus,
a .text-extra-light-gray-color.link.white:hover,
a .text-extra-light-gray-color.link.white:focus,
.text-gray-color.link.white a:hover,
.text-gray-color.link.white a:focus,
a.text-gray-color.link.white:hover,
a.text-gray-color.link.white:focus,
a .text-gray-color.link.white:hover,
a .text-gray-color.link.white:focus,
a:hover .text-gray-color.link.white,
a:focus .text-gray-color.link.white,
.text-black-color.link.white a:hover,
.text-black-color.link.white a:focus,
a.text-black-color.link.white:hover,
a.text-black-color.link.white:focus,
a .text-black-color.link.white:hover,
a .text-black-color.link.white:focus,
a:hover .text-black-color.link.white,
a:focus .text-black-color.link.white{
    color: var(--bs-white) !important;
}
.text-shad{
    text-shadow: 0 5px 10px rgba(0, 0, 0, .25);
}

/********************************************************** Helper Classes ******************************************************************/

.bord{
    height: 1px;
}
.bord.min{
    width: 100px;
}
.bord.w1000{
    max-width: 1000px;
}
.bord.w1200{
    max-width: 1200px;
}
.bord-primary-color{
    border-color: var(--primary-color) !important;
}
.bord-secondary-color{
    border-color: var(--secondary-color) !important;
}
.bord-lightest-gray-color{
    border-color: var(--lightest-gray-color) !important;
}
.bord-extra-light-gray-color{
    border-color: var(--extra-light-gray-color) !important;
}
.bord-light-gray-color{
    border-color: var(--light-gray-color) !important;
}
.bord-gray-color{
    border-color: var(--gray-color) !important;
}
.bord-dark-gray-color{
    border-color: var(--dark-gray-color) !important;
}
.bord-black-color{
    border-color: var(--black-color) !important;
}
.zIt{
    position: relative;
    z-index: 2;
}
.hide{
    visibility: hidden;
}
.bg-primary-color{
    background-color: var(--primary-color) !important;
}
.bg-secondary-color{
    background-color: var(--secondary-color) !important;
}
.bg-lightest-gray-color{
    background-color: var(--lightest-gray-color) !important;
}
.bg-extra-light-gray-color{
    background-color: var(--extra-light-gray-color) !important;
}
.bg-light-gray-color{
    background-color: var(--light-gray-color) !important;
}
.bg-gray-color{
    background-color: var(--gray-color) !important;
}
.bg-dark-gray-color{
    background-color: var(--dark-gray-color) !important;
}
.bg-black-color{
    background-color: var(--black-color) !important;
}
.slick-track{
    display: flex !important;
}
.slick-slide{
    height: inherit !important;
}
.overlay.black:after{
    background-color: var(--black-color) !important;
}
.overlay.primary:after{
    background-color: var(--primary-color) !important;
}
.overlay.secondary:after{
    background-color: var(--secondary-color) !important;
}
.overlay.white:after{
    background-color: var(--bs-white) !important;
}
.overlay.op25:after{
    opacity: .25;
}
.overlay.op50:after{
    opacity: .5;
}
.overlay.op75:after{
    opacity: .75;
}
.overlay.op80:after{
    opacity: .8;
}
.overlay.op85:after{
    opacity: .85;
}
.overlay.op90:after{
    opacity: .9;
}

/*:root{
    --body-color: #56636D;
	--primary-color: #1B3C58;
	--primary-color-hover: #2E6694;
	--primary-color-rgba: 27, 60, 88;
	--primary-color-rgba-hover: 46, 102, 148;
	--secondary-color: #A5B3BE;
	--secondary-color-hover: #778C9D;
	--secondary-color-rgba: 165, 179, 190;
	--secondary-color-rgba-hover: 119, 140, 157;
	--lightest-gray-color: #EFEFEF;
	--extra-light-gray-color: #C8C8C8;
	--light-gray-color: #969696;
	--gray-color: #646464;
	--dark-gray-color: #323232;
	--black-color: #151515;
}*/