/* Main CSS */
body {
    background-color: #EEEEEE;
}
.cpl-20{
    padding-left: 20px !important;
}
.text-red{
    color: #CC0000;
}
.text-blue{
    color: #319BE2;
}
.text-green{
    color: #6CD404;
}
.text-orange{
    color: #FF8153;
}
.text-pink{
    color: #E6234B;
}
.text-small-italic{
    font-style: italic;
    color: #D2D2D2;
}
.card {
    border-radius: 35px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-bottom: 20px;
    background-color: #FFFFFF;
}
.card-body {
    padding-top: 0;
}
.custom-padding {
    padding-left: 10px;
    padding-right: 10px;
}
.file-uploader-box{
    background-color: #EEEEEE;
    border-radius: 10px;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.input-main{
    background-color: #EEEEEE;
    border-radius: 10px;
    padding: 12px;
    border: none;
}
.input-alt{
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 7px 10px;
    border: none;
}
.btn-blue {
    display: inline-flex; /* Use inline-flex to maintain inline nature of a tag */
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 10px 25px;
    background-color: #319BE2;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 550;
    font-size: 12px;
    letter-spacing: 1.5px;
    border: none;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
    transition: border-radius 0.2s ease;
    text-align: center;
    text-decoration: none; /* Removes underline for links */
    line-height: 1; /* Ensures no extra space above/below the text */
    height: 100%; /* Ensures the flex container fills its parent */
    box-sizing: border-box; /* Includes padding in the height calculation */
}
.btn-blue:hover {
    background-color: #46C0E1;
    color: #FFFFFF;
    border-radius: 50px;
}
.btn-blue:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(49, 155, 226, 0.5);
}
.btn-red {
    display: inline-flex; /* Use inline-flex to maintain inline nature of a tag */
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 10px 25px;
    background-color: #CC0000;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 550;
    font-size: 12px;
    letter-spacing: 1.5px;
    border: none;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
    transition: border-radius 0.2s ease;
    text-align: center;
    text-decoration: none; /* Removes underline for links */
    line-height: 1; /* Ensures no extra space above/below the text */
    box-sizing: border-box; /* Includes padding in the height calculation */
    /* Removed height: 100%; */
}
.btn-red:hover:not(:disabled) {
    background-color: #B30000;
    color: #FFFFFF;
    border-radius: 50px;
}
.btn-red:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(49, 155, 226, 0.5);
}
.btn-red:disabled {
    background-color: #A0A0A0;
    color: #FFFFFF;
    cursor: not-allowed;
    border-radius: 8px;
    opacity: 0.6;
}
input[type="checkbox"] {
    border: 1px solid #319BE2;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
}
input[type="radio"] {
    border: 1px solid #319BE2;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
}
.b-text{
    font-size: 13px;
    font-weight: bold;
    color: #1E1E1E;
}
/* Messages */

.alert-item,
.message-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; /* For positioning tags */
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
    padding: 15px;
    cursor: pointer;
}

.unread-message-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #319BE2;
    display: inline-block;
    color: white;
}

.unread-alert-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #FF4558;
    display: inline-block;
    color: white;
}

.read-message-icon,
.read-alert-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #D2D2D2;
    display: inline-block;
    color: white;
}

.tag {
    display: inline-block;
    border-radius: 15px;
    padding: 6px 8px;
    margin-right: 5px;
    font-size: 12px;
    line-height: 1; /* Ensures the text height doesn't affect layout */
    white-space: nowrap; /* Prevents text from wrapping */
    vertical-align: middle; /* Aligns badges vertically in line */
}

.tag-green {
    background-color: #6CD404;
    color: white;
}

.tag-yellow {
    background-color: #FFCB05;
    color: white;
}

.tag-blue {
    background-color: #319BE2;
    color: white;
}

.tag-gray {
    background-color: #929292;
    color: white;
}

.tag-purple {
    background-color: #F43EF9;
    color: white;
}

.tag-red {
    background-color: #FF4558;
    color: white;
}

.message-content {
    flex: 1;
    padding-left: 15px;
}

.message-controls {
    display: flex;
    justify-content: space-between;
}

.message-details {
    flex: 1;
    margin-left: 15px;
    position: relative;
}

.message-details .tag-container {
    display: flex;
    position: absolute;
    bottom: -25px;
    left: -8px;
    width: calc(100% - 30px); /* Width adjustment to prevent overflow */
}

.icon-badge-container {
    position: relative;
    display: inline-block;
}

.icon-badge-container .badge {
    position: absolute;
    bottom: -4px; /* Adjust this value to position vertically */
    right: -1px;  /* Adjust this value to position horizontally */
    font-size: 12px; /* Adjust if needed */
    padding: 2px 6px; /* Adjust if needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #319BE2;
}

.custom-pagination {
    display: inline-flex;
    padding: 0;
    overflow: hidden;
    border-radius: 10px;
    height: 35px;
}

.custom-pagination .page-item .page-link {
    border: none;
    background-color: rgb(191, 191, 191);
    color: rgb(255, 255, 255);
    width: 30px; /* Reduced from 35px to 30px */
    height: 35px; /* Kept at 35px */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 0;
    margin: 0;
    border-radius: 0;
    font-size: 12px;
}

.custom-pagination .page-item.active .page-link {
    background-color: rgb(255, 255, 255);
    color: rgb(191, 191, 191);
    z-index: 1;
}

.custom-pagination .page-item:not(.active) .page-link:hover {
    background-color: rgb(170, 170, 170);
}

.custom-pagination .page-link:focus {
    box-shadow: none;
    outline: 2px solid rgb(100, 100, 100);
    outline-offset: -2px;
}

.attachment-item a {
    color: #676767;
    text-decoration: underline;
}

.image-preview img {
    width: 100%; /* Ensures the image takes up the full width of its container */
    height: 120px; /* Set a fixed height to ensure consistency across images */
    object-fit: cover; /* Ensures the image covers the container, cropping if necessary */
    border-radius: 5px; /* Optional: Adds rounded corners for aesthetics */
}

.custom-dropdown-menu {
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    margin-top: 0 !important;
    transform: translate(0, 0) !important;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-radius: 0 0 0.5rem 0.5rem;
}
.tag-container{

}